module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@4.22.0_gatsby@2.32.13_@types+node@20.12.12_@types+react@16.14.26_@t_mp2wjslu56cdcwz46zqu2lmcv4/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XTZ68FJHLS","UA-239120813-1"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-netlify-cms@4.3.9_gatsby@2.32.13_@types+node@20.12.12_@types+react@16.14.26_@ty_qd7gc553nv4jt3xmfndyh67fcu/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-hubspot@1.3.4_gatsby@2.32.13_@types+node@20.12.12_@types+react@16.14.26_@types+_iapsguyu6svtzpqac6boqwq73q/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7040627","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-remark-images@3.5.0_gatsby-plugin-sharp@2.6.9_gatsby@2.32.13_@types+node@20.12.12_@typ_ssqfvvgf27pacy4ld42goa3kpi/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":500},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@2.4.9_gatsby@2.32.13_@types+node@20.12.12_@types+react@16.14.26_@types_d7pqwt3dxuop2kgv2d46byhlp4/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/_img/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"05da2373672bb6d913d365c672ac19a9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
